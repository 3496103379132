import { _, media } from 'app/styles/breakpoint';
import styled from 'styled-components';

export const FooterContainerWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.footerBackground};
  width: 100%;
  margin-top: auto;
`;

export const FooterContainer = styled.div`
  width: 100%;

  ${media.max.md`
  padding-left: 42px;
  padding-right: 42px;
`}
  ${media.max.sm`
  padding-left: 34px;
  padding-right: 34px;
`}
 ${media.max.xs`
  padding-left: 20px;
  padding-right: 20px;
`}
 ${media.max.xs`
  min-width: 250px;
`}

background-color: ${(props) => props.theme.colors.footerBackground};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${_(media.min.lg)} {
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const FooterLinkItem = styled.div`
  font-size: 14px;
  line-height: 20px;
  /* color: ${(props) => props.theme.colors.white}; */
  color: rgba(255, 255, 255, 0.9);

  cursor: pointer;
  > a {
    /* color: ${(props) => props.theme.colors.white}; */
    color: rgba(255, 255, 255, 0.9);

    margin-right: 10px;
  }
  > a:hover {
    color: ${(props) => props.theme.colors.white};
  }
  > a:active: {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  .eu-flag {
    margin-right: 25px;
    margin-bottom: 15px;
  }
`;

export const SocialItem = styled.a`
  width: 31px;
  height: 31px;
  border-radius: 24px;
  margin-right: 14px;
  :last-child {
    margin-right: 0px;
  }
  background-color: rgba(255, 255, 255, 0.7);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

export const Icon = styled.svg`
  flex: none;
`;

export const IconPath = styled.path`
  fill: rgb(2, 12, 85);
  ${SocialItem}:hover & {
    fill: rgb(2, 12, 85);
  }
`;

export const LegalInfoText = styled.div`
  font-size: 12px;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.grayDark};
  margin-right: 15px;

  :last-child {
    margin-right: 0px;
  }
  > a {
    color: inherit;
    text-decoration: inherit;
  }
`;

export const SupportWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  width: 100%;
  line-height: 20px;
  ${_(media.max.sm)} {
    .sales-and-support {
      width: 100%;
    }
  }

  > div {
    display: flex;
    > div {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  ${_(media.max.tablet)} {
    display: block;
  }
  ${_(media.max.sm)} {
    > div {
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
    }
  }
`;

export const PolicyWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${_(media.max.sm)} {
    justify-content: center;
  }
  > div {
    display: flex;
    line-height: 20px;
  }

  > div {
    margin-right: 25px;
  }
  /* > div > div:first-child {
    ${_(media.max.sm)} {
      margin-bottom: 15;
    }
  } */
  > div:last-child {
    margin-right: 0;
  }

  a {
    color: ${(props) => props.theme.colors.white};
    font-size: 14px;
    margin-bottom: 15px;
  }
  a:hover {
    color: ${(props) => props.theme.colors.grayDark};
  }
`;

export const FlexDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  > div {
    display: flex;
  }
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  ${_(media.max.sm)} {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  ${_(media.max.xs)} {
    justify-content: center;
    flex-direction: column;
  }
`;

export const GridDisplay = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 16px;

  &.middle-container {
    padding: 20px 0;
    border: 1px solid ${({ theme }) => theme.colors.footerLine};
    border-left: 0;
    border-right: 0;
    align-items: center;
  }
  &.top-container {
    margin: 64px 0;
  }

  &.top-container .block > *:not(:last-child) {
    margin-bottom: 10px;
  }
  .block {
    grid-column: span 3;
  }
  .to-end {
    grid-column: span 9;
  }

  ${_(media.max.md)} {
    grid-column-gap: 14px;
  }
  ${_(media.max.tablet)} {
    grid-column-gap: 10px;
    grid-row-gap: 40px;

    &.top-container {
      margin: 40px 0;
    }
    &.top-container .block {
      grid-column: span 6;
    }
    .to-end {
      justify-content: flex-end;
    }
  }
  ${_(media.max.sm)} {
    &.middle-container {
      display: flex;
      flex-direction: column;
    }
    &.middle-container .block {
      margin-bottom: 16px;
    }
    &.middle-container .block > div {
      margin: 0;
    }
    &.middle-container .block:last-child {
      margin: 0;
      display: grid;
      grid-column-gap: 25px;
      grid-template-columns: repeat(2, 1fr);
    }
    &.middle-container .block:last-child > *:nth-child(odd) {
      text-align: right;
    }
  }
  ${_(media.max.xs)} {
    &.top-container {
      grid-row-gap: 15px;
      margin: 25px 0;
    }
    &.top-container .block {
      grid-column: span 12;
    }
  }
`;
