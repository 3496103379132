import styled from 'styled-components';

// custom imports
import { _, media } from 'app/styles/breakpoint';

export const PharmaceuticalManufacturersContainerWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
`;

export const PharmaceuticalManufacturersContainer = styled.div`
  ${_(media.max.xs)} {
    padding: 0 15px 47px 15px;
  }
  ${_(media.min.sm)} {
    padding: 0 5% 47px 5%;
  }
  ${_(media.min.tablet)} {
    padding: 0 34px 47px 34px;
  }
  ${_(media.min.md)} {
    padding: 0 42px 47px 42px;
  }
  ${_(media.min.lg)} {
    padding-left: 0;
    padding-right: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 1180px;
  }

  [class^='breadcrumb__BreadcrumContainer'] {
    padding-left: 0;
    margin-left: 0;
  }
`;

export const TitleContainer = styled.div`
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  margin: 30px 0 39px 0;
`;

export const LinksContainer = styled.div`
  padding-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
`;

export const LinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  ${_(media.min.lg)} {
    width: 553px;
    margin-right: 33px;
  }
  ${_(media.max.md)} {
    flex: 1;
    margin-right: 10px;
  }
`;

export const LinkItem = styled.div`
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;
