import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

// custom imports
import { routes } from 'app/config';
import { setMarketplaceFilter } from 'app/store/modules/marketplace/actions';
import { setProductFilter } from 'app/store/modules/products/actions';
import { IStore } from 'app/store/types';
import Footer from './footer';

interface IDispatchProps {
  clearFilter?: (route: string) => any;
}

export default withRouter(
  connect<any, IDispatchProps, RouteComponentProps, IStore>(
    null,
    (dispatch, ownProps) => {
      const clearFilterFun = (route: string) => {
        if (route === routes.products) {
          dispatch(
            setProductFilter({
              company: '',
              form: '',
              product: '',
              visible: true,
              lastUpdateFrom: undefined,
              lastUpdateTo: undefined,
            })
          );
        }

        if (route === routes.marketplace) {
          dispatch(
            setMarketplaceFilter({
              isNavbar: true,
              product: '',
              productId: undefined,
            })
          );
        }
      };
      return {
        clearFilter: (route: string) => {
          clearFilterFun(route);
        },
      };
    }
  )(Footer)
);
