import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';

// custom imports
import { IWithApolloClient } from 'app/store/modules/global/types';
import { fetchManufacturerTypesInCountry } from 'app/store/modules/manufacturers/actions';
import { IStore } from 'app/store/types';
import PharmaceuticalManufacturersInCountry from './pharmaceutical-manufacturers-in-country';

interface IDispatchProps {
  fetchManufacturerTypes: (
    countryId: string,
    pageNr: number,
    pageSize: number
  ) => any;
}

interface IStateProps {
  manufacturerTypesInCountry: any;
  isLoading: boolean;
}

interface IOwnProps {
  pageNr?: number;
  country?: string;
  pageSize?: number;
}

export interface IProps extends IStateProps, IDispatchProps, IOwnProps {}

export default withApollo<IOwnProps>(
  connect<IStateProps, IDispatchProps, IWithApolloClient<IOwnProps>, IStore>(
    (state) => {
      return {
        manufacturerTypesInCountry:
          state.manufacturers.manufacturerTypesInCountry,
        isLoading: state.manufacturers.isFetching,
      };
    },
    (dispatch, ownProps) => ({
      fetchManufacturerTypes: (
        countryId: string,
        pageNr: number,
        pageSize: number
      ) => {
        dispatch(
          fetchManufacturerTypesInCountry({
            client: ownProps.client,
            countryId,
            pageNr,
            pageSize,
          })
        );
      },
    })
  )(PharmaceuticalManufacturersInCountry)
);
