import styled from 'styled-components';

// custom imports
import { _, media } from 'app/styles/breakpoint';
import { AnchorLink } from 'app/components/links';

export const GridDisplay = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 16px;

  .block > *:not(:last-child) {
    margin-bottom: 10px;
  }
  .block {
    grid-column: span 3;
  }

  ${_(media.max.md)} {
    grid-column-gap: 14px;
  }
  ${_(media.max.tablet)} {
    grid-column-gap: 10px;
    grid-row-gap: 40px;
    .block {
      grid-column: span 6;
    }
  }
  ${_(media.max.xs)} {
    grid-row-gap: 15px;
    margin: 25px 0;
    .block {
      grid-column: span 12;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  padding-bottom: 60px;
  ${_(media.max.md)} {
    padding-left: 42px;
    padding-right: 42px;
  }
  ${_(media.max.sm)} {
    padding-left: 34px;
    padding-right: 34px;
  }
  ${_(media.max.xs)} {
    padding-left: 20px;
    padding-right: 20px;
  }
  ${_(media.max.xs)} {
    min-width: 250px;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${_(media.min.lg)} {
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }

  hr {
    margin: 0;
    width: 100%;
    border-width: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.grayDivider};
  }
  &.lined hr {
    display: block;
  }
`;

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  width: 100%;
`;

export const Item = styled(AnchorLink)`
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  text-decoration: none;
  color: ${(props) => props.theme.colors.grayText};
  cursor: pointer;
  margin: 10px 0;
`;

export const Header = styled.h1`
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  width: 100%;
  margin: 25px 0;
  margin-top: 60px;
`;
