import { random, shuffle, startCase } from 'lodash';
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';

// custom imports
import { renderLinks } from 'app/modules/manufacturers/manufacturers-by-country/components/manufacturers-in-country/helpers';
import {
  LinksColumn,
  LinksContainer,
} from 'app/modules/manufacturers/manufacturers-by-country/components/manufacturers-in-country/styles';
import {
  Container,
  Header,
  Wrapper,
} from 'app/modules/manufacturers/popular-manufacturers/styles';
import { routes } from 'app/config';
import { useSelector } from 'app/helpers/redux';
import { allCountries } from 'app/store/modules/global/types';
import classNames from 'app/utils/class-names';

interface IProps {
  manufacturerTypesInCountry?: any;
  fetchManufacturerTypes?: any;
}

const PharmaceuticalManufacturersInCountry = ({
  manufacturerTypesInCountry = [],
  fetchManufacturerTypes,
}: IProps) => {
  const pageSize = 10;
  const minPageNr = 0;
  const maxPageNr = 15; // safe default number, majority has max of 15
  const numberOfLinksInOneColumn = 5;
  const routeMatch: any = useRouteMatch();
  const seoable = useSelector((state) => state.marketplace.substanceSEOable);
  const isCountryPage =
    routeMatch.path === routes.company_directory_country ||
    routeMatch.path === routes.company_directory_country_company ||
    routeMatch.path === routes.marketplaceSearchCountry ||
    routeMatch.path === routes.marketplaceSearchCountryPage;
  const country: string = routeMatch.params.country || '';

  const [pageNr, setPageNr] = React.useState<number>(
    random(minPageNr, maxPageNr)
  );
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [manufacturerTypes, setManufacturerTypes]: any = React.useState([]);

  useEffect(() => {
    if (isCountryPage) {
      const foundCountry = allCountries.find(
        (countryObj: any) => countryObj.name.toLowerCase() === country
      );
      const countryId: string = (foundCountry && foundCountry.id) || '-';

      if (
        !manufacturerTypesInCountry[countryId] ||
        manufacturerTypesInCountry[countryId].pageNr !== pageNr
      ) {
        fetchManufacturerTypes(countryId, pageNr, pageSize);
      }
    }
  }, [pageNr]);

  useEffect(() => {
    const foundCountry = allCountries.find(
      (countryObj) => countryObj.name.toLowerCase() === country
    );
    const countryId: string =
      (foundCountry && foundCountry.id) || 'country_not_found';

    if (manufacturerTypesInCountry[countryId]) {
      const filteredManufacturerTypes = manufacturerTypesInCountry[
        countryId
      ].manufacturerTypes.filter(
        (manufacturerType: string) => !manufacturerType.includes('%')
      );
      setManufacturerTypes(shuffle(filteredManufacturerTypes));
      setTotalCount(manufacturerTypesInCountry[countryId].totalCount);
    }
  }, [manufacturerTypesInCountry]);

  React.useEffect(() => {
    const getTotalPages = () => {
      const linksInOnePage = 100;

      return Math.ceil(totalCount / linksInOnePage) || pageNr;
    };

    const shouldRefetch =
      (manufacturerTypes.length < pageSize &&
        totalCount >= manufacturerTypes.length) ||
      pageNr > getTotalPages();

    if (shouldRefetch) {
      setPageNr(random(minPageNr, getTotalPages()));
    }
  }, [totalCount]);

  return country.length > 0 && manufacturerTypes.length > 0 && isCountryPage ? (
    <Wrapper>
      <Container className={classNames(seoable && 'lined')}>
        <hr />
        <Header>Pharmaceutical manufacturers in {startCase(country)}</Header>
        <LinksContainer>
          <LinksColumn>
            {renderLinks(
              manufacturerTypes.slice(0, numberOfLinksInOneColumn),
              country
            )}
          </LinksColumn>
          <LinksColumn>
            {renderLinks(
              manufacturerTypes.slice(numberOfLinksInOneColumn, pageSize),
              country
            )}
          </LinksColumn>
        </LinksContainer>
      </Container>
    </Wrapper>
  ) : (
    <div />
  );
};
export default PharmaceuticalManufacturersInCountry;
