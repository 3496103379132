import React from 'react';
import { Link } from 'react-router-dom';

// custom imports
import PharmaceuticalManufacturersInCountry from 'app/modules/manufacturers/pharmaceutical-manufacturers-in-country';
import PopularManufacturers from 'app/modules/manufacturers/popular-manufacturers';
import { routes } from 'app/config';
import {
  FlexDisplay,
  FooterContainer,
  FooterContainerWrapper,
  FooterLinkItem,
  GridDisplay,
  Icon,
  IconPath,
  LegalInfoText,
  PolicyWrapper,
  SocialContainer,
  SocialItem,
  SupportWrapper,
} from './styles';
import EUFlag from '../next/atoms/icons/eu-flag';

interface Props {
  clearFilter?: (route: string) => any;
}
const Footer: React.FC<Props> = ({ clearFilter }) => {
  const year = new Date().getFullYear();

  interface IRoute {
    label: string;
    route: string;
    action?: () => void;
  }
  const route: IRoute[][] = [
    [
      {
        label: 'Contact us',
        route: routes.contacts,
      },
      {
        label: 'Partnering with us',
        route: routes.partnering,
      },
      {
        label: 'About us',
        route: routes.about_us,
      },
      {
        label: 'Blog',
        route: routes.blog,
      },
    ],
    [
      {
        label: 'Pharmaceuticals for sale',
        route: routes.marketplace,
        action: () => {
          clearFilter && clearFilter(routes.marketplace);
        },
      },
      {
        label: 'Assets for sale',
        route: routes.companies,
      },
      {
        label: 'For buyers',
        route: routes.buyer,
      },
      {
        label: 'For sellers',
        route: routes.seller,
      },
      {
        label: 'FAQ',
        route: routes.faqforsellers,
      },
    ],
    // [
    //   {
    //     label: 'Pharmaceutical companies for sale',
    //     route: routes.companies,
    //   },
    //   {
    //     label: 'How to buy pharmaceutical companies',
    //     route: routes.companies_home_buyer,
    //   },
    //   {
    //     label: 'How to sell pharmaceutical companies',
    //     route: routes.companies_home_seller,
    //   },
    // ],
    [
      {
        label: 'Pharmaceutical companies directory',
        route: routes.company_directory,
      },
      {
        label: 'Pharmaceutical manufacturers by country',
        route: routes.pharmaceutical_manufacturers_countries,
      },
      {
        label: 'Pharmaceutical manufacturers',
        route: `${routes.pharmaceutical_manufacturers.split(':')[0]}1`,
      },
    ],
  ];

  const isNextRoute = (routeString: string) => {
    const nextRoutes = [
      routes.about_us,
      routes.partnering,
      routes.buyer,
      routes.seller,
      routes.blog,
      routes.marketplace,
    ];
    if (nextRoutes.indexOf(routeString) >= 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <PharmaceuticalManufacturersInCountry />
      <PopularManufacturers />

      <FooterContainerWrapper className="__footer">
        <FooterContainer>
          <GridDisplay className="top-container">
            {route.map((block, blockKey) => (
              <div className="block" key={blockKey}>
                {block.map(({ route: r, label, action }, itemKey) => (
                  <FooterLinkItem key={itemKey}>
                    {isNextRoute(r) ? (
                      <a href={r}>{label}</a>
                    ) : (
                      <Link to={r} onClick={action}>
                        {label}
                      </Link>
                    )}
                  </FooterLinkItem>
                ))}
              </div>
            ))}
          </GridDisplay>
          <FlexDisplay className="middle-container">
            <div>
              <div>
                <SocialContainer>
                  {/* <SocialItem
                  href={'https://www.facebook.com/pipelinepharma/'}
                  target={'_blank'}
                >
                  <Icon
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="16"
                    viewBox="0 0 10 16"
                  >
                    <IconPath d="M3.023 16L3 9H0V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H10L9 9H6.28v7H3.023z" />
                  </Icon>
                </SocialItem> */}
                  {/* <SocialItem
                  href={'https://www.linkedin.com/company/pipelinepharma/'}
                  target={'_blank'}
                >
                  <Icon
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                  >
                    <IconPath
                      fillRule="evenodd"
                      d="M3.404 15H.19V4.88h3.214V15zM1.797 3.498h-.021C.697 3.498 0 2.72 0 1.749 0 .756.718 0 1.818 0s1.778.756 1.798 1.749c0 .971-.697 1.749-1.819 1.749zM5.185 15s.042-9.171 0-10.12H8.4v1.467h-.021c.423-.69 1.184-1.705 2.919-1.705C13.413 4.642 15 6.088 15 9.197V15h-3.216V9.586c0-1.361-.465-2.29-1.63-2.29-.887 0-1.416.627-1.647 1.231-.087.216-.107.519-.107.821V15H5.185z"
                    />
                  </Icon>
               
                </SocialItem> */}

                  {/* <SocialItem
                  href={'https://twitter.com/pipelinepharma'}
                  target={'_blank'}
                >
                  <Icon
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                  >
                    <IconPath
                      fillRule="nonzero"
                      d="M16 1.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7C5.2 3.9 2.7 2.6 1.1.6c-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z"
                    />
                  </Icon>
                </SocialItem> */}
                </SocialContainer>
              </div>
              <PolicyWrapper className="block to-end">
                <div>
                  <a
                    href="https://www.pipelinepharma.com/gdpr-compliant"
                    className="eu-flag"
                  >
                    <EUFlag />
                  </a>
                </div>
                <div>
                  <Link to={routes.gdpr_compliant}>GDPR compliant</Link>
                </div>
                <div>
                  <Link to={routes.terms_of_service}>Terms of service</Link>
                </div>
                <div>
                  <Link to={routes.privacy}>Privacy policy</Link>
                </div>
                <div>
                  <Link to={routes.cookies}>Cookie policy</Link>
                </div>
              </PolicyWrapper>
            </div>

            <SocialContainer>
              <SocialItem
                href={'https://www.linkedin.com/company/pipelinepharma/'}
                target={'_blank'}
              >
                <Icon
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                >
                  <IconPath
                    fillRule="evenodd"
                    d="M3.404 15H.19V4.88h3.214V15zM1.797 3.498h-.021C.697 3.498 0 2.72 0 1.749 0 .756.718 0 1.818 0s1.778.756 1.798 1.749c0 .971-.697 1.749-1.819 1.749zM5.185 15s.042-9.171 0-10.12H8.4v1.467h-.021c.423-.69 1.184-1.705 2.919-1.705C13.413 4.642 15 6.088 15 9.197V15h-3.216V9.586c0-1.361-.465-2.29-1.63-2.29-.887 0-1.416.627-1.647 1.231-.087.216-.107.519-.107.821V15H5.185z"
                  />
                </Icon>
              </SocialItem>
            </SocialContainer>
          </FlexDisplay>
          <SupportWrapper>
            <div>
              <LegalInfoText className={'sales-and-support'}>
                SALES AND SUPPORT
              </LegalInfoText>
              <LegalInfoText>
                <a href="tel:+37052075842">+370-5-207-5842</a>
              </LegalInfoText>
              <LegalInfoText>
                <a
                  href="mailto:support@pipelinepharma.com"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  support@pipelinepharma.com
                </a>
              </LegalInfoText>
            </div>
            <LegalInfoText>
              © {year} Pipelinepharma. All rights reserved. EU patent number
              7.069.242
            </LegalInfoText>
          </SupportWrapper>
        </FooterContainer>
      </FooterContainerWrapper>
    </>
  );
};

export default Footer;
