import { chunk } from 'lodash';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';

// custom imports
import { generatePath, routes } from 'app/config';
import { useSelector } from 'app/helpers/redux';
import classNames from 'app/utils/class-names';
import { Container, GridDisplay, Header, Item, Wrapper } from './styles';

const List = [
  'Losartan manufacturer',
  'Metformin manufacturer',
  'Oxycodone manufacturer',
  'Lisinopril manufacturer',
  'Gabapentin manufacturer',
  'Tramadol manufacturer',
  'Paracetamol manufacturer',
  'Ibuprofen manufacturer',
  'Heparin manufacturer',
  'Atorvastatin manufacturer',
  'Valsartan manufacturer',
  'Ranitidine manufacturer',
  'Sertraline manufacturer',
  'Omeprazole manufacturer',
  'Calcium carbonate manufacturer',
  'Naloxone manufacturer',
  'Amlodipine manufacturer',
  'Prednisolone manufacturer',
  'Methotrexate manufacturer',
  'Amoxicillin manufacturer',
];

const PopularManufacturers: React.FC = (props) => {
  const route = useLocation();
  const routeMatch = useRouteMatch();
  const routesToShow = [
    routes.home,
    routes.company_directory_country,
    routes.company_directory,
    routes.pharmaceutical_manufacturers_countries,
    routes.pharmaceutical_manufacturers_country_page,
    routes.pharmaceutical_manufacturers_country,
    routes.marketplace,
    routes.pharmaceutical_manufacturers,
    routes.product,
  ];
  const seoable = useSelector((state) => state.marketplace.substanceSEOable);
  const divideBy = 4;
  if (
    routesToShow.includes(routeMatch.path) ||
    route.pathname.includes('-manufacturers')
  ) {
    return (
      <Wrapper>
        <Container className={classNames(seoable && 'lined')}>
          <hr />
          <Header>Popular pharmaceutical manufacturers</Header>
          <GridDisplay>
            {chunk(List, List.length / divideBy).map((part, index) => (
              <div className="block" key={index}>
                {part.map((element, elementIndex) => (
                  <div key={elementIndex}>
                    <Item
                      href={generatePath(routes.marketplaceSearch, {
                        keyword: element
                          .toLocaleLowerCase()
                          .replace(/manufacturer$/, 'manufacturers')
                          .trim()
                          .split(' ')
                          .join('-'),
                      })}
                    >
                      {element}
                    </Item>
                  </div>
                ))}
              </div>
            ))}
          </GridDisplay>
        </Container>
      </Wrapper>
    );
  } else {
    return <div />;
  }
};
export default PopularManufacturers;
