import { AnchorLink } from 'app/components/links';
import { startCase } from 'lodash';
import React from 'react';

// custom imports
import { LinkItem } from './styles';

export const adaptToUrl = (words: string) =>
  words.toLowerCase().replace(/ /g, '-');

export const renderLinks = (manufacturerTypes: string[], country: string) => {
  return manufacturerTypes.map((manufacturerType) => (
    <LinkItem key={manufacturerType}>
      <AnchorLink
        href={`/${manufacturerType
          .toLowerCase()
          .replace(/ /g, '-')}-manufacturers/country/${adaptToUrl(country)}`}
      >
        {manufacturerType} manufacturers in {startCase(country)}
      </AnchorLink>
    </LinkItem>
  ));
};
